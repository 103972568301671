import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrderParams } from '../../models/OrderParams.model';

const storedOrderParamsStr = localStorage.getItem('orderParams');

export const initialOrderParams: OrderParams = {
  cardsPerBox: 6,
  nonHitCount: 5400,
  nonHitVarietyCount: 6,
  nonHitVarietyNames: [
    'Pack Type 1',
    'Pack Type 2',
    'Pack Type 3',
    'Pack Type 4',
    'Pack Type 5',
    'Pack Type 6',
  ],
  semiHitCount: 500,
  semiHitName: 'Semi Hit Type',
  hardHitCount: 50,
  hardHitName: 'Hard Hit Type',
};

const initialState: OrderParams = storedOrderParamsStr
  ? (JSON.parse(storedOrderParamsStr) as OrderParams)
  : initialOrderParams;

export const orderParamsSlice = createSlice({
  name: 'orderParams',
  initialState,
  reducers: {
    setOrderParams: (_, action: PayloadAction<OrderParams>) => {
      localStorage.setItem('orderParams', JSON.stringify(action.payload));
      return action.payload;
    },
    resetOrderParams: () => {
      localStorage.removeItem('orderParams');
      return initialOrderParams;
    },
  },
});

// Action creators are generated for each case reducer function
export const selectOrderParams = (state: RootState) => state.orderParams;

export const { setOrderParams, resetOrderParams } = orderParamsSlice.actions;

export default orderParamsSlice.reducer;
