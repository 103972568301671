import './App.css';
import Header from './header/Header';
import OrderGenerator from './order-generator/OrderGenerator';
import OrderParamsForm from './order-params/OrderParamsForm';
import OrderResults from './order-results/OrderResults';

function App() {
  return (
    <div className="App">
      <Header />
      <OrderParamsForm />
      <div className='mt-12'>
        <OrderGenerator />
      </div>
      <div className='mt-16'>
        <OrderResults />
      </div>
    </div>
  );
}

export default App;
