import { configureStore } from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import orderParamsReducer from './slices/order-params.slice';
import orderResultsReducer from './slices/order-results.slice';
import generatingOrderLoaderReducer from './slices/generating-order-loader.slice';

export const store = configureStore({
  reducer: {
    orderParams: orderParamsReducer,
    orderResults: orderResultsReducer,
    generatingOrderLoader: generatingOrderLoaderReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
