
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const generatingOrderLoaderSlice = createSlice({
  name: 'generatingOrderLoader',
  initialState: false,
  reducers: {
    setGeneratingOrderLoader: (_, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const selectGeneratingOrderLoader = (state: RootState) => state.generatingOrderLoader;

export const { setGeneratingOrderLoader } = generatingOrderLoaderSlice.actions;

export default generatingOrderLoaderSlice.reducer;
