import Button from '../components/form/Button';
import { selectOrderParams } from '../store/slices/order-params.slice';
import { useAppDispatch, useAppSelector } from '../store/store';
import { randomNumberBetween } from '../utils/random-number-between';
import { remove, clone } from 'ramda';
import { OrderParams } from '../models/OrderParams.model';
import { PackSet } from '../models/PackSet.model';
import { PackBox } from '../models/PackBox.model';
import { Order } from '../models/Order.model';
import { setOrderResults } from '../store/slices/order-results.slice';

export class OrderGeneratorProps {}
const OrderGenerator: React.FC<OrderGeneratorProps> = props => {
  const {} = props;
  const orderParams = useAppSelector(selectOrderParams);
  const dispatch = useAppDispatch();

  return (
    <div className="flex justify-center">
      <Button
        classes="max-w-[16rem]"
        text="GENERATE ORDER!"
        type="button"
        onClick={() => {
          const orderResults = generateOrder(orderParams);
          dispatch(setOrderResults(orderResults));
          alert('Your order is generating!');
        }}
      />
    </div>
  );
};
export default OrderGenerator;

const generateOrder = (orderParams: OrderParams): Order => {
  let nonHitPacks: PackSet[] = Array(orderParams.nonHitVarietyCount)
    .fill('x')
    .map((_, i) => ({
      count: orderParams.nonHitCount / orderParams.nonHitVarietyCount,
      packSetName: orderParams.nonHitVarietyNames?.[i]
        ? orderParams.nonHitVarietyNames?.[i]
        : `Non hit type ${i + 1}`,
    }));

  let semiHitPackSet: PackSet = {
    count: orderParams.semiHitCount,
    packSetName: orderParams?.semiHitName ? orderParams?.semiHitName : 'Semi Hit Pack',
  };

  let hardHitPackSet: PackSet = {
    count: orderParams.hardHitCount,
    packSetName: orderParams?.hardHitName ? orderParams?.hardHitName : 'Hard Hit Pack',
  };

  const totalBoxes = Math.floor(
    (orderParams.nonHitCount +
      orderParams.semiHitCount +
      orderParams.hardHitCount) /
      orderParams.cardsPerBox
  );

  const hardHitPositions: number[] = Array(hardHitPackSet.count)
    .fill('x')
    .reduce<number[]>(accum => {
      let potentialPosition = -1;
      while (
        potentialPosition < 1 ||
        Boolean(accum.find(x => x === potentialPosition))
      ) {
        potentialPosition = randomNumberBetween(1, totalBoxes);
      }
      return [...accum, potentialPosition];
    }, []);

  const semiHitPositions: number[] = Array(semiHitPackSet.count)
    .fill('x')
    .reduce<number[]>(accum => {
      let potentialPosition = -1;
      while (
        potentialPosition < 1 ||
        Boolean(accum.find(x => x === potentialPosition)) ||
        Boolean(hardHitPositions.find(x => x === potentialPosition))
      ) {
        potentialPosition = randomNumberBetween(1, totalBoxes);
      }
      return [...accum, potentialPosition];
    }, []);

  let boxes = [] as PackBox[];

  Array(totalBoxes)
    .fill('x')
    .forEach((_, i) => {
      const results = makeBox(
        i + 1,
        orderParams.cardsPerBox,
        semiHitPositions,
        hardHitPositions,
        {
          nonHitPacks: clone(nonHitPacks),
          semiHitPackSet: clone(semiHitPackSet),
          hardHitPackSet: clone(hardHitPackSet),
        }
      );
      nonHitPacks = results.nonHitPacks;
      semiHitPackSet = results.semiHitPackSet;
      hardHitPackSet = results.hardHitPackSet;
      boxes.push(results.box);
    });

  const sortedBoxes = boxes.map(b => ({
    ...b,
    packSets: b.packSets.sort(),
  }));

  const boxTemplates = sortedBoxes.reduce((accum, next) => {
    const key = JSON.stringify(next.packSets);
    if (!accum[key]) {
      return { ...accum, [key]: 1 };
    } else {
      return { ...accum, [key]: accum[key] + 1 };
    }
  }, {});
  return {
    boxes: sortedBoxes,
    semiHitPositions,
    hardHitPositions,
    boxTemplates,
    remainders: {
      nonHitPacks: nonHitPacks.map(nonHitPack => ({
        name: nonHitPack.packSetName,
        remainder: nonHitPack.count,
      })),
      semiHitPackSet: semiHitPackSet.count,
      hardHitPackSet: semiHitPackSet.count,
    },
  };
};

const makeBox = (
  boxNumber: number,
  cardsPerBox: number,
  semiHitPositions: number[],
  hardHitPositions: number[],
  packs: {
    nonHitPacks: PackSet[];
    semiHitPackSet: PackSet;
    hardHitPackSet: PackSet;
  }
): {
  box: PackBox;
  nonHitPacks: PackSet[];
  semiHitPackSet: PackSet;
  hardHitPackSet: PackSet;
} => {
  // console.log(`making box ${boxNumber}`);
  const { nonHitPacks, semiHitPackSet, hardHitPackSet } = packs;
  const hasSemiHit = Boolean(
    semiHitPositions.find(position => position === boxNumber)
  );
  const hasHardHit = Boolean(
    hardHitPositions.find(position => position === boxNumber)
  );

  if (!hasSemiHit && !hasHardHit) {
    let candidatePackSets = nonHitPacks;
    const boxPacks = [] as string[];
    while (boxPacks.length < cardsPerBox) {
      const packToAddIndex = randomNumberBetween(
        0,
        candidatePackSets.length - 1
      );
      const packToAdd = candidatePackSets[packToAddIndex];
      packToAdd.count = packToAdd.count - 1;

      boxPacks.push(packToAdd.packSetName);
      candidatePackSets = remove(packToAddIndex, 1, candidatePackSets);
    }
    return {
      box: {
        boxNumber: boxNumber,
        packSets: boxPacks,
      },
      nonHitPacks,
      semiHitPackSet,
      hardHitPackSet,
    };
  }

  if (hasSemiHit) {
    const nonHitPacksExcludingOne = excludeOnePackFromCandidates(nonHitPacks);
    let candidatePackSets = [...nonHitPacksExcludingOne, semiHitPackSet];
    const boxPacks = [] as string[];
    while (boxPacks.length < cardsPerBox) {
      const packToAddIndex = randomNumberBetween(
        0,
        candidatePackSets.length - 1
      );
      const packToAdd = candidatePackSets[packToAddIndex];
      packToAdd.count = packToAdd.count - 1;

      boxPacks.push(packToAdd.packSetName);
      candidatePackSets = remove(packToAddIndex, 1, candidatePackSets);
    }
    return {
      box: {
        boxNumber: boxNumber,
        packSets: boxPacks,
      },
      nonHitPacks,
      semiHitPackSet,
      hardHitPackSet,
    };
  }

  if (hasHardHit) {
    const nonHitPacksExcludingOne = excludeOnePackFromCandidates(nonHitPacks);
    let candidatePackSets = [...nonHitPacksExcludingOne, hardHitPackSet];
    const boxPacks = [] as string[];
    while (boxPacks.length < cardsPerBox) {
      const packToAddIndex = randomNumberBetween(
        0,
        candidatePackSets.length - 1
      );
      const packToAdd = candidatePackSets[packToAddIndex];
      packToAdd.count = packToAdd.count - 1;

      boxPacks.push(packToAdd.packSetName);
      candidatePackSets = remove(packToAddIndex, 1, candidatePackSets);
    }
    return {
      box: {
        boxNumber: boxNumber,
        packSets: boxPacks,
      },
      nonHitPacks,
      semiHitPackSet,
      hardHitPackSet,
    };
  }
  throw new Error('Cant compute correct HIT strategy');
};

const excludeOnePackFromCandidates = (packs: PackSet[]): PackSet[] => {
  const lowestPackCount = packs.reduce<number>((accum, next) => {
    if (next.count < accum) {
      return next.count;
    }
    return accum;
  }, Infinity);
  const packsWithLowestCount = packs.filter(
    pack => pack.count === lowestPackCount
  );
  const nameOfPackToExclude =
    packsWithLowestCount[
      randomNumberBetween(0, packsWithLowestCount.length - 1)
    ].packSetName;
  const newCandidatePacks = packs.filter(
    pack => pack.packSetName !== nameOfPackToExclude
  );
  return newCandidatePacks;
};
