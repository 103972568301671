import {update} from 'ramda';
import {useState} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../components/form/Button';
import Input from '../components/form/Input';
import { OrderParams } from '../models/OrderParams.model';
import {
  initialOrderParams,
  resetOrderParams,
  selectOrderParams,
  setOrderParams,
} from '../store/slices/order-params.slice';
import { clearOrderResults } from '../store/slices/order-results.slice';
import { useAppDispatch, useAppSelector } from '../store/store';

export class OrderParamsFormProps {}
const OrderParamsForm: React.FC<OrderParamsFormProps> = props => {
  const {} = props;

  const dispatch = useAppDispatch();
  const defaultOrderParams = useAppSelector(selectOrderParams);
  const [varietyNames, setVarietyNames] = useState<string[]>(defaultOrderParams.nonHitVarietyNames);

  const methods = useForm<OrderParamsFormValues>({
    defaultValues: orderParamsToOrderParamsForm(defaultOrderParams),
    mode: 'all',
  });
  const { handleSubmit, reset, getValues, watch } = methods;
  watch();
  const submit = (data: OrderParamsFormValues) => {
    dispatch(setOrderParams(orderParamsFormToOrderParams(data, varietyNames)));
    alert('New order paramaters applied');
  };

  const resetForm = () => {
    reset(orderParamsToOrderParamsForm(initialOrderParams));
    dispatch(resetOrderParams());
    dispatch(clearOrderResults());
  };

  return (
    <div>
      <FormProvider {...methods}>
        <p className="cursor-pointer text-red-500" onClick={resetForm}>
          Reset
        </p>
        <form onSubmit={handleSubmit(submit)}>
          <Input name="cardsPerBox" type="number" labelStr="Cards Per Box" />
          <Input name="nonHitCount" type="number" labelStr="Non Hit Count" />
          <Input
            name="nonHitVarietyCount"
            type="number"
            labelStr="Non Hit Variety Count"
          />
          <div className="ml-8">
            {typeof Number(getValues().nonHitVarietyCount) === 'number' &&
              Number(getValues().nonHitVarietyCount) > 0 &&
              Array(Number(getValues().nonHitVarietyCount))
                .fill('x')
                .map((_, i) => {
                  return (
                    <Input
                      key={i}
                      name={`varietyName${i + 1}`}
                      type="text"
                      defaultValue={varietyNames[i]}
                      labelStr={`Variety${i + 1} Name`}
                      onChange={(val) => {
                        setVarietyNames(prev => {
                          return update(i, val, prev)
                        })
                      }}
                    />
                  );
                })}
          </div>
          <Input name="semiHitCount" type="number" labelStr="Semi Hit Count" />
          <div className='ml-8'>
            <Input name="semiHitName" type="text" labelStr="Semi Hit Name" />
          </div>
          <Input name="hardHitCount" type="number" labelStr="Hard Hit Count" />
          <div className='ml-8'>
            <Input name="hardHitName" type="text" labelStr="Hard Hit Name" />
          </div>
          <div className="mt-6 max-w-[16rem]">
            <Button type="submit" text="Apply Params" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default OrderParamsForm;

class OrderParamsFormValues {
  cardsPerBox: string;
  nonHitCount: string;
  nonHitVarietyCount: string;
  semiHitCount: string;
  semiHitName: string;
  hardHitCount: string;
  hardHitName: string;
}

const orderParamsFormToOrderParams = (
  formVals: OrderParamsFormValues,
  varietyNames: string[],
): OrderParams => ({
  cardsPerBox: Number(formVals.cardsPerBox),
  nonHitCount: Number(formVals.nonHitCount),
  nonHitVarietyCount: Number(formVals.nonHitVarietyCount),
  nonHitVarietyNames: varietyNames,
  semiHitCount: Number(formVals.semiHitCount),
  semiHitName: formVals.semiHitName,
  hardHitCount: Number(formVals.hardHitCount),
  hardHitName: formVals.hardHitName,
});

const orderParamsToOrderParamsForm = (
  vals: OrderParams
): OrderParamsFormValues => ({
  cardsPerBox: String(vals.cardsPerBox),
  nonHitCount: String(vals.nonHitCount),
  nonHitVarietyCount: String(vals.nonHitVarietyCount),
  semiHitCount: String(vals.semiHitCount),
  semiHitName: vals.semiHitName,
  hardHitCount: String(vals.hardHitCount),
  hardHitName: vals.hardHitName,
});
