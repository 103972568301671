import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Order } from '../../models/Order.model';

const storedOrderResultsStr = localStorage.getItem('orderResults');

export const orderResultsSlice = createSlice({
  name: 'orderResults',
  initialState: storedOrderResultsStr
    ? JSON.parse(storedOrderResultsStr) as Order
    : null,
  reducers: {
    setOrderResults: (_, action: PayloadAction<Order>) => {
      localStorage.setItem('orderResults', JSON.stringify(action.payload));
      return action.payload;
    },
    clearOrderResults: () => {
      localStorage.removeItem('orderResults');
      return null;
    },
  },
});

// Action creators are generated for each case reducer function
export const selectOrderResults = (state: RootState) => state.orderResults;

export const { setOrderResults, clearOrderResults } = orderResultsSlice.actions;

export default orderResultsSlice.reducer;
