export class HeaderProps {
  
}
const Header: React.FC<HeaderProps> = (props) => {
  const {} = props;
  return (
    <div>CARDINATOR</div>
  );
};
export default Header;
