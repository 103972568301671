import { useFormContext } from 'react-hook-form';

export class InputProps {
  classes?: string;
  name: string;
  labelStr: string;
  type: 'number' | 'text';
  onChange?: (...args: any[]) => any;
  defaultValue?: string;
}
const Input: React.FC<InputProps> = props => {
  const { classes = '', name, type, labelStr, onChange, defaultValue = '' } = props;
  const { register } = useFormContext();

  const inputProps = {
    ...(defaultValue && { defaultValue }),
    ...(onChange && { onChange: (e: any) => onChange(e.target.value) }),
  }
  return (
    <div
      className={`grid grid-cols-1 justify-start w-full p-4 max-w-[16rem] ${classes}`}
    >
      <label className="text-left" htmlFor={name}>
        {labelStr}
      </label>
      <input
        className="border-gray-400 border-2 rounded"
        type={type}
        {...(register ? register(name) : {})}
        defaultValue={defaultValue}
        {...inputProps}
      />
    </div>
  );
};
export default Input;
