import Button from '../components/form/Button';
import { selectOrderParams } from '../store/slices/order-params.slice';
import { selectOrderResults } from '../store/slices/order-results.slice';
import { useAppSelector } from '../store/store';
import {Order} from '../models/Order.model';

export class OrderResultsProps {}
const OrderResults: React.FC<OrderResultsProps> = props => {
  const {} = props;
  const orderResults = useAppSelector(selectOrderResults);
  const orderParams = useAppSelector(selectOrderParams);

  if (!orderResults) {
    return <div></div>;
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-center">
        <Button
          text="Download Order"
          onClick={() => {
            downloader(generateCsvString(orderResults));
            alert('Downloading order');
          }}
          type="button"
          classes="w-[16rem]"
        />
      </div>
      <div>
        <h1>Stats</h1>
        <p>Generated {orderResults.boxes.length} boxes</p>
        <p>cards remaining:</p>
        <p>Hard hits: {orderResults.remainders.hardHitPackSet}</p>
        <p>Semi hits: {orderResults.remainders.semiHitPackSet}</p>
        {orderResults.remainders.nonHitPacks.map((packSet, i) => {
          return (
            <p key={i}>
              {packSet.name}: {packSet.remainder}
            </p>
          );
        })}
      </div>
      <div className='text-left'>
        <p>Box Templates + counts:</p>
        {orderResults?.boxTemplates &&
          Object.entries(orderResults.boxTemplates).map(([key, value], i) => {
            console.log('round');
            return <p key={i}>{key.replace('[', '').replace(']', '').replace(/","/g, ',      ').replace(/"/g, '')}: {value}</p>
        })}
      </div>
      <table>
        <thead>
          <tr>
            <th className="p-2"></th>
            <th className="p-2">#</th>
            {Array(orderParams.cardsPerBox)
              .fill('x')
              .map((_, i) => {
                return (
                  <th key={i} className="p-2">
                    Pack {i + 1}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {orderResults.boxes.map((box, i) => {
            return (
              <tr key={i}>
                <td className="p-2">
                  <input type="checkbox" />
                </td>
                <td className="p-2">{box.boxNumber}</td>
                {box.packSets.map((pack, i) => {
                  return (
                    <td key={i} className="p-2">
                      {pack}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default OrderResults;

const generateCsvString = (orderResults: Order) => {
  return `
        Generated ${orderResults.boxes.length} boxes
        cards remaining:
        Hard hits, ${orderResults.remainders.hardHitPackSet}
        Semi hits, ${orderResults.remainders.semiHitPackSet}
        ${orderResults.remainders.nonHitPacks.map((packSet, i) => {
          return `${packSet.name},`;
        })}
        ${orderResults.remainders.nonHitPacks.map((packSet, i) => {
          return `${packSet.remainder},`;
        })}



        Box Templates + counts:
        ${orderResults?.boxTemplates &&
          Object.entries(orderResults.boxTemplates).map(([key, value], i) => {
            const sets = key.replace('[', '').replace(']', '').replace(/","/g, ',      ').replace(/"/g, '');
            return `${i === 0 ? ',' : ''}${sets}, ${value}\n`;
        })}


        Box Details:
        ,Box #,
        ${orderResults.boxes.map((box, i) => {
          return `${i === 0 ? ',' : ''}${box.boxNumber}, ${box.packSets.join(',')}\n`;
        })}
  `;
};

const downloader = (csvString) => {
    // Creating a Blob for having a csv file format  
    // and passing the data with type 
    const blob = new Blob([csvString], { type: 'text/csv' }); 
  
    // Creating an object for downloading url 
    const url = window.URL.createObjectURL(blob) 
  
    // Creating an anchor(a) tag of HTML 
    const a = document.createElement('a') 
  
    // Passing the blob downloading url  
    a.setAttribute('href', url) 
  
    // Setting the anchor tag attribute for downloading 
    // and passing the download file name 
    a.setAttribute('download', 'card-pack-order.csv'); 
  
    // Performing a download with click 
    a.click() 
}
