export class ButtonProps {
  onClick?: (...args) => any;
  text: string;
  type: 'button' | 'submit';
  classes?: string;
}
const Button: React.FC<ButtonProps> = props => {
  const { onClick = () => '', text, type, classes = '' } = props;
  return (
    <div className={`px-4 ${classes}`}>
      <button
        className="border-cyan-500 rounded border-2 w-full text-cyan-500 cursor-pointer px-4"
        onClick={onClick}
        type={type}
      >
        {text}
      </button>
    </div>
  );
};
export default Button;
